.actionMenuBx {
    .MuiPaper-elevation{
        .MuiList-root{
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            z-index: 99;
            li{
                font-size: 12px;
                color: #4C4F53;
                font-weight: 600;
                border-bottom: #E6E6E6 solid 1px;
                padding: 7px 12px;
                &:last-child{border-bottom: none;}
            }
        }
        min-width: 170px;
        box-shadow:  none !important;
        overflow: visible;
    }
}