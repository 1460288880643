@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap");

/*Fonts*/
$lightFont: 300;
$regularFont: 400;
$mediumFont: 500;
$semiboldFont: 600;
$boldFont: 700;
$extraBold: 800;
$extraBlackFont: 900;

$font24: 1.5rem;
$font10: 0.625rem;
$font12: 0.75rem;
$font13: 0.813rem;
$font14: 0.875rem;
$font20: 1.25rem;

/*Fonts*/

html {
    font-size: 16px;
}
body {
    font-family: "Nunito", sans-serif;
    color: $textColor;
    min-height: 100vh;
    margin: 0;
    font-size: 14px;
    @include below-1100-desktop {
        font-size: 14px;
    }
    @include for-tablet-portrait {
        font-size: 13px;
        overflow-x: hidden;
    }
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: $textColor;
    margin-bottom: 0;
}

h1,
.h1 {
}
h2,
.h2 {
    font-size: 2.25rem;
}
h3,
.h3 {
    font-size: 1.5rem;
}
h4,
.h4 {
}
h5,
.h5 {
}
h6,
.h6 {
    font-size: 1.25rem;
}
.regularFont {
    font-weight: $regularFont;
}
.fontMedium {
    font-weight: $mediumFont;
}
.fontSemiBold {
    font-weight: $semiboldFont;
}
.fontBold {
    font-weight: $boldFont;
}
.fontExtraBold {
    font-weight: $extraBold;
}
.textDarkGrey {
    color: $textDarkerGrey;
}
.smallPrimaryText {
    font-size: $font14;
    font-weight: $semiboldFont;
    color: $primary;
}
.left-carrot-icon {
    transform: rotate(90deg);
}
.smallLink {
    font-size: $font12;
}
.page-heading {
    h2 {
        color: #292f3f;
        font-size: 18px;
        font-weight: 600;
    }
}
