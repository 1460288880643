*:focus {
    box-shadow: none !important;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    color: $textColor;

    &:hover {
        color: $textColor;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.MuiContainer-root {
    max-width: 100%;
    padding: 0 15px;
}

.footer {
    margin-top: 25px;
}

.errorHelperTxt {
    &.MuiFormHelperText-root {
        margin-left: 0;
        margin-right: 0;
        color: $danger;
        position: absolute;
        left: 0;
        top: calc(100% - 3px);
        font-size: 11px;
    }
}

.breadcrumbNavs {
    padding-bottom: 15px;
    .MuiBreadcrumbs-ol {
        li {
            font-size: 12px;
            line-height: 1;
            color: #888e97;

            a {
                line-height: 1;
                color: #606975;
            }
            &.MuiBreadcrumbs-separator {
                margin-left: 5px;
                margin-right: 5px;
            }
            p {
                font-size: 12px;
                color: #888e97;
            }
        }
    }
    .pageHeading {
        h1 {
            font-size: 18px;
            margin-top: 10px;
        }
    }
}

.formLabelCntr {
    text-align: right;
    @include for-tablet-portrait {
        text-align: left;
    }
}

.specificationList {
    margin: 15px 0;
}

.d-none {
    display: none !important;
}

.filters_helper_text_block {
    background-color: #f7f7f7 !important;
    padding: 6px 20px 6px 10px !important;
    position: relative;

    .fliter-type-items-container {
        display: flex;

        .fliter-type-items-info {
            display: flex;
            align-items: flex-start;
            margin: 0px 10px;

            span {
                padding-left: 5px;
            }
        }
    }

    .filter-type-close-info {
        position: absolute;
        right: 40px;
        top: 15px;
    }
}
.multi-filter-checkbox-block {
    display: flex;
    position: relative;

    .multi-filter-checkbox-title {
        font-weight: 700;
        position: absolute;
        top: 10px;
    }

    .triStateCheckBox {
        margin: 35px 20px 10px 0;

        label {
            padding-left: 20px !important;
        }
    }
}
.internal-column-icons {
    display: flex;
    align-items: center;
}
/* For Levy Schemes Date Picker Button Icon Position Fix */
.c_date button {
    margin-top: 18px;
}
/* For cbs tab color Fix */
.secondary-tabs .MuiTab-textColorSecondary.Mui-selected {
    color: #b253ae;
}

.secondary-tabs .PrivateTabIndicator-colorSecondary-4 {
    background-color: #b253ae !important;
    position: absolute;
    height: 2px;
    bottom: 0;
}
.categories-dashboard-table-chekbox-icon-block svg {
    height: 16px;
}

.headlogo {
    height: 41px;
}

.mobile-menu-logo {
    height: auto;
}

/* Login Page */

.main-container-block {
    background: url("../assets/images/bg2.jpg");
    background-position: top left;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: grid;
    place-items: center;

    .user-login-block {
        background-color: #fff;
        text-align: center;
        padding: 40px;
        -webkit-box-shadow: 1px 1px 10px 0px #333;
        box-shadow: 1px 1px 50px 0px #333;
        border-radius: 10px;

        .nus-logo {
            padding: 0 0 40px 0;
        }

        .user-login {
            padding: 40px 0 0 0;

            .user-login-btn {
                background-color: #00aec7;
                font-size: 18px;
                letter-spacing: 0.5px;
            }
        }
    }
}
.uploaded-files-list {
    margin-top: 10px;
    .invoiceListAlert {
        padding-right: 0;
        padding-bottom: 0;
        .MuiAlert-message {
            width: 100%;
        }
        .invoiceScroll {
            height: 120px;
            overflow-y: scroll;
        }
        ol {
            padding-left: 0;
            list-style-type: decimal;
            list-style-position: inside;
            margin-bottom: 0;
        }
    }
}
.lookup-btns span svg {
    font-size: 14px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.summary-discount-headers {
    background-color: rgba(178, 83, 174, 0.8) !important;
}

.summary-discount-headers .MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-columnHeaderTitle {
    color: #fff !important;
}

.active-member-outlet
{
    color: green;
}

.inactive-member-outlet
{
    color: red;
}

/*Loader*/
.loadingWarmer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.15);
    text-align: center;
    .text {
        color: $primary;
        margin: 5px auto;
        font-weight: 600;
    }

    .bounceball {
        position: relative;
        display: inline-block;
        height: 37px;
        width: 15px;
    }
    .bounceball:before {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $primary;
        transform-origin: 50%;
        -webkit-animation: bounce 500ms alternate infinite ease;
        animation: bounce 500ms alternate infinite ease;
    }
}

@-webkit-keyframes bounce {
    0% {
        top: 30px;
        height: 5px;
        border-radius: 60px 60px 20px 20px;
        transform: scaleX(2);
    }
    35% {
        height: 15px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100% {
        top: 0;
    }
}

@keyframes bounce {
    0% {
        top: 30px;
        height: 5px;
        border-radius: 60px 60px 20px 20px;
        transform: scaleX(2);
    }
    35% {
        height: 15px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100% {
        top: 0;
    }
}
/*Loader*/
.processManagerFltrBx{
    .filter-fields, hr{display: none;}
    .filter-checkboxes{margin-top: 0;}
}

/*Date Picker Adjustments for Adding Today Button */
.css-51rckg-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-11i6j86-MuiButtonBase-root-MuiPickersDay-root.Mui-selected
{
    color: #fff !important;
}
.css-1yllih9-MuiPaper-root-MuiPickersPopper-paper,
.css-gztimk-MuiPaper-root-MuiPickersPopper-paper
{
    border: 1px solid #ccc;
}

button.home-ecs-off-btn {
    background-color: #930;
    color: #fff;

    .Mui-disabled {
        background-color: unset;
        color: unset
    }
}