$primary: #B253AE;
$secondary: #08aded;
$success: #296941;
$danger: #D32F2F;
$warning: #ffd700;
$info: #00c9ff;
$white: #ffffff;
$lightBlue: #e5f8f2;
$grey: #333333;
$textLightGrey: #918f8f;
$textDarkerGrey: #7b7879;
$borderColor: #cccccc;
$black: #000;
$greyBg: #eaeaea;
$darkYellow: #ffcc00;
$meh: #f8d580;
$textColor: #15191E;
$lightSuccess: #dcedc4;
$lightDanger: #f8cbcb;
$inputBorderColor: #ccdedc;
$modalHeaderBg: #F0F0F0;
$mediumBlueBg: #cee8f7;
$lightGreen: #e4f5d6;
$bodyBg: #f2f5f7;
$form-feedback-margin-top: 0.75rem;
$form-feedback-font-size: 0.75rem;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

$carousel-caption-color: $textColor;

$inputTextColor: #bcbbbb;
$inputLabelColor: #908e8f;
$textDarkerGrey: $textDarkerGrey;
$checkbox: #26BE8D;
$footerBg: #f1f1f1;

/*export for MUI*/

:export{
    primary: $primary;
    secondary: $secondary;
    success: $success;
    danger: $danger;
    warning: $warning;
    white: $white;
    black: $black;
    textColor: $textColor;
    checkbox: $checkbox;
    footerBg: $footerBg
}
/*export for MUI*/
