// .form-group,
// .input-group {
//     .invalid-feedback {
//         color: $form-feedback-invalid-color;
//         font-size: $form-feedback-font-size;
//     }
// }
// label {
//     font-size: 0.875rem;
//     color: $inputLabelColor;
// }
// .form-control {
//     height: auto;
//     padding: 0.4rem 0.5rem;
//     font-size: 0.85rem;
//     border-radius: 3px;
//     color: $inputTextColor;
//     border-color: $inputBorderColor;
//     &:focus {
//         box-shadow: none !important;
//         background-color: #fff !important;
//         border-color: $inputBorderColor;
//     }
//     &.lockedPrimaryEmail {
//         &:disabled {
//             background-color: transparent;
//             cursor: not-allowed;
//         }
//     }
//     &.form-control-sm {
//         padding: 0.25rem 0.5rem;
//     }
//     &::placeholder {
//         color: #231F2080;
//         opacity: 1; 
//     }

//     &:-ms-input-placeholder {
//         color: #231F2080;
//     }

//     &::-ms-input-placeholder {
//         color: #231F2080;
//     }
// }
// input[type="search"] {
//     &.form-control {
//         // background: url(/images/search-icon.svg) 13px center no-repeat;
//         padding-left: 45px;
//         border-color: $success;
//         border-radius: 10px;
//     }
// }
// .input-group-text {
//     font-size: 0.875rem;
//     color: $textLightGrey;
//     border-color: $inputBorderColor;
//     padding-left: 1.5rem;
//     padding-right: 1.5rem;
// }

// .was-validated .form-control:invalid,
// .form-control.is-invalid {
//     background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAABHNCSVQICAgIfAhkiAAAAJRJREFUKFN9kssRgCAMBXk9iK3p0SL89KBVeIPWtIgIfiOEMMOJtxOyCfa27UFkK+8noxyew940c8gO4S7W+1HiImCIFgLW2rkOMaSBKRDzJ1QCJeAHpaABNv4l/u23klDRPD2kfebQ3fQdFOX8IN5DGMNWsvqJSLRqVi/lAqBZhQaUwHMjgqU6TlpdI5bL7Gng83YAeieHDjIyB70AAAAASUVORK5CYII=");
//     background-size: 13px auto;
// }

// .inputWithSideIndicator {
//     .form-control:valid,
//     .form-control.is-valid {
//         border: $success solid 1px;
//         border-right: none;
        
//         ~ .input-group-text {
//         }
//     }
//     .input-group.is-invalid {
//         .form-control:invalid,
//         .form-control.is-invalid {
//             border-color: $danger;
//             border-right: none;
//             ~ .input-group-text {
//             }
//         }
//     }
// }
// .was-validated {
//     .inputWithSideIndicator {
//         .form-control:valid,
//         .form-control.is-valid {
//             border-right: none;
//             ~ .input-group-text {
//             }
//         }
//         .form-control:invalid,
//         .form-control.is-invalid {
//             border-right: none;
//             ~ .input-group-text {
//             }
//         }
//         .input-group.is-invalid {
//             .input-group-text {
//             }
//         }
//     }
// }
// .otpContainer {
//     .form-control {
//         font-size: $font24;
//         font-weight: $semiboldFont;
//         color: $textColor;
//     }
//     .form-control:valid,
//     .form-control.is-valid,
//     .form-control:invalid,
//     .form-control.is-invalid {
//         background-image: none;
//         padding-left: 10px;
//         padding-right: 10px;
//         text-align: center;
//     }
//     .form-control:valid,
//     .form-control.is-valid {
//         border-color: $success;
//     }

//     .form-control.is-invalid,
//     .form-control.is-invalid:focus {
//         border-color: $danger;
//     }
// }
// .searchBox {
//     width: 35vw;
// }
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//     background-color: #fff;
// }

.date-picker-input{
    position: relative;
    .date-picker-icon{
        position: absolute;
        right: 14px;
        top: 50%;
        margin-top: 1px;
    }
    .MuiFormControl-root{
        background-color: transparent;
        .MuiOutlinedInput-root{
            background-color: $white;
        }
    }
}

.triStateCheckBox{position: relative;}
.triStateCheckBox input[type="checkbox"] { display: none }
.triStateCheckBox svg{display: none; position: absolute; left: 0; top: 0; }
.triStateCheckBox input[type="checkbox"] + label i {  display: inline-block; vertical-align: middle; }
.triStateCheckBox input[type="checkbox"] + label i .chk_notchecked{display: block;}
.triStateCheckBox input[type="checkbox"]:checked + label i .chk_checked{ display: block; }
.triStateCheckBox input[type="checkbox"]:checked + label i .chk_notchecked{display: none;}
.triStateCheckBox input[type="checkbox"]:indeterminate + label i .chk_interchecked{ display: block; }
.triStateCheckBox input[type="checkbox"]:indeterminate + label i .chk_notchecked{ display: none; }
.triStateCheckBox input[type="checkbox"] + label { color: #a2a2a2; font-size: 14px; padding: 0; padding-left: 27px; cursor: pointer; color: $textColor; }
.input-group{
    position: relative;
}
.mandatory{
    position: relative;
    &::after{
        content: '*';
        position: absolute;
        top: -5px;
        right: -5px;
        color: $danger;
    }
    &.mandatory2{
        &::after{
            position: relative;
        }
    }
}
input[type="number"] {
    -moz-appearance: textfield;
}
.MuiFormControl-root{
    &.min-input-width{
        min-width: 225px;
        @include for-large-phone{
            min-width: 100%;
        }
    }
}
.date-picker-input{
    .MuiFormControl-root{
        min-width: 225px;
        @include for-large-phone{
            min-width: 100%;
        }
    }
}
.BrandOwnerNameBx{
    margin-bottom: 24px;
    @include for-large-phone{
        width: 100%;
        .table-actionable{
            margin-top: 25px;
        }
    }
}
