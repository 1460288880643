// .ag-header-cell-text {
//     font-size: 11px !important;
// }
// .ag-header-cell {
//     font-size: 11px !important;
// }
// div.ag-theme-alpine div.ag-row {
//     font-size: 11px !important;
// }
// .ag-paging-panel {
//     justify-content: space-between !important;
// }
// .filter-with-table {
//     .ag-theme-alpine .ag-header-cell,
//     .ag-theme-alpine .ag-header-group-cell {
//         padding-left: 10px;
//         padding-right: 10px;
//     }
//     .ag-theme-alpine .ag-cell {
//         padding-left: 10px;
//         padding-right: 10px;
//     }
// }

// .exportBtnsTables {
//     .exportIconBtn {
//         padding: 5px 8px;
//         font-size: 0.75rem;
//         border-radius: 50%;
//         width: 30px;
//         height: 30px;
//         background-color: $primary;
//         cursor: pointer;
//     }
//     .export-icon {
//         width: 100%;
//         svg {
//             fill: #fff;
//         }
//     }
// }
// .pageSizeSelect {
//     position: absolute;
//     bottom: 10px;
//     left: 15px;
//     z-index: 1;
//     display: flex;
//     align-items: center;
// }
// .ag-theme-alpine {
//     .ag-paging-row-summary-panel-number {
//         font-weight: 400 !important;
//     }
//     .ag-paging-panel {
//         > span[role~="status"] {
//             margin-left: auto;
//             margin-right: auto;
//             padding-left: 20vw;
//             > span {
//                 &[ref="lbFirstRowOnPage"] {
//                     &::before {
//                         display: inline-block;
//                         content: "showing ";
//                         font-weight: 400;
//                         margin-right: 5px;
//                     }
//                 }
//                 &[ref="lbLastRowOnPage"] {
//                     &::after {
//                         display: inline-block;
//                         content: " out ";
//                         font-weight: 400;
//                         margin-left: 5px;
//                     }
//                 }
//                 &[ref="lbRecordCount"] {
//                     &::after {
//                         display: inline-block;
//                         content: " entries";
//                         font-weight: 400;
//                         margin-left: 5px;
//                     }
//                 }
//             }
//         }
//     }
// }

// .ag-theme-alpine .ag-root-wrapper {
//     border-color: #e0e2e3 !important;
// }
// .ag-theme-alpine .ag-header {
//     border-color: #e0e2e3 !important;
//     background-color: #f2f5f7 !important;
// }
// .ag-theme-alpine .ag-row {
//     color: #384352 !important;
//     &:hover {
//         &.ag-row-hover {
//             background-color: rgba(33, 150, 243, 0.1) !important;
//         }
//     }
//     &.ag-row-selected {
//         background-color: rgba(33, 150, 243, 0.1) !important;
//     }
// }
// .ag-theme-alpine .ag-row-odd {
//     background-color: #f2f5f7 !important;
// }

.filter-panel-box {
    background-color: #f0f0f0;
    border: #c0c0c0 solid 1px;
    padding: 15px 20px 15px 20px;
    border-radius: 5px;
    .filter-fields{
        > .MuiBox-root{
            margin-bottom: 1.25rem;
            @include for-large-phone{
                margin-bottom: 1.25rem;
            }
            .MuiOutlinedInput-input, .MuiSelect-nativeInput{
                font-size: 0.85rem;
            }
            .MuiFormLabel-root{
                padding-bottom: 7px;
            }
        }
    }
    .filter-checkboxes{
        margin-top: 1rem;
        .filterChkBx{
            @include for-large-phone{
                flex-wrap: wrap;
                column-gap: 12px;
                > .MuiBox-root{
                    width: auto;
                    margin-bottom: 15px;
                    .MuiFormControlLabel-root{
                        margin-right: 0;
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}
.main-content {
    padding-top: 85px;
    min-height: 75vh;
    @include for-large-phone{
        padding-top: 75px;
    }
}
.MuiPagination-root {
    margin-right: 8px;
    @include for-large-phone{
        width: 100%;
    }
}
.MuiPagination-ul {
    margin-right: 15px;
    li {
        button {
            background-color: $primary;
            color: $white;
            &.Mui-selected {
                color: $textColor;
                &:hover {
                    color: $textColor;
                }
            }
            &:hover {
                background-color: $primary;
                color: $white;
            }
            @include for-large-phone{
                min-width: auto;
                width: 23px;
                height: 23px;
                font-size: 0.6rem;
            }
        }
        &:first-child,
        &:last-child {
            button {
                background: none;
                color: $textColor;
                border: none;
                padding: 0;
                svg {
                    font-size: 1.75rem;
                }
            }
        }
    }
    @include for-large-phone{
        justify-content: center;
    }
}

.MuiDataGrid-footerContainer {
    > div:first-child {
        display: none;
    }
    .grid-pagiation {
        .customRowPerPageBox {
            > div {
                display: inline-block;
                vertical-align: middle;
                &.MuiBox-root {
                    .MuiSelect-select {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
            }
            padding-left: 15px;
            @include for-large-phone{
                width: 100%;
                text-align: center;
                padding: 15px 0;
            }
        }
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include for-large-phone{
            padding-bottom: 15px;
        }
    }
}

.table-actions-buttons {
    .data-per-row-message {
        order: 1;
        @include for-large-phone {
            order: 2;
            text-align: center;
        }
    }
    .exportsButtons {
        order: 2;
        @include for-large-phone {
            order: 1;
            .table-actionable {
                margin-bottom: 15px;
            }
        }
        .importButtons{
            margin-right: auto;
        }
    }
    @include for-large-phone {
        display: block !important;
    }
}

.exportsButtons {
    .importButtons{
        margin-right: auto;
        display: flex;
        .excelList{
            margin-left: 15px;
            max-width: 325px;
            text-align: left;
        }
      /* Add productType style */
        .productType {
            margin-left: 15px;  /* Space between dropdowns */
            max-width: 200px;   /* Set max width for Product Type dropdown */
            text-align: left;   /* Align text inside the dropdown */
        }
    }
}
