.accordion {
    box-shadow: 0px 0px 20px #0000000f;
    padding: 0 15px;
    .accordion-item {
        .accordion-header {
            .accordion-button {
                font-size: 1rem;
                color: $textColor;
                font-weight: $semiboldFont;
                position: relative;
                &:not(.collapsed) {
                    box-shadow: none !important;
                    background: transparent;
                    color: $textColor;
                    &:after {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy43ODgiIGhlaWdodD0iNy42NDQiIHZpZXdCb3g9IjAgMCAxMy43ODggNy42NDQiPgogIDxwYXRoIGlkPSJTdHJva2VfMSIgZGF0YS1uYW1lPSJTdHJva2UgMSIgZD0iTTExLjY2NywwLDUuODMzLDUuODMzLDAsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIuNzI3IDYuNTgzKSByb3RhdGUoLTE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwNWM1MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
                        transform: rotate(-0deg);
                    }
                }
                padding: 15px 0;
                &:after {
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy43ODgiIGhlaWdodD0iNy42NDQiIHZpZXdCb3g9IjAgMCAxMy43ODggNy42NDQiPgogIDxwYXRoIGlkPSJTdHJva2VfMSIgZGF0YS1uYW1lPSJTdHJva2UgMSIgZD0iTTExLjY2NywwLDUuODMzLDUuODMzLDAsMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIuNzI3IDYuNTgzKSByb3RhdGUoLTE4MCkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwNWM1MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
                    transform: rotate(-180deg);
                    width: 20px;
                    height: 11px;
                }
                .filterOptionsChecked {
                    position: absolute;
                    right: 35px;
                }
            }
        }
        .accordion-body {
            padding: 7px 1px 20px 12px;
        }
    }
}
