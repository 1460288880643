// .btn {
//     text-transform: capitalize;
//     font-weight: 500;
//     border-radius: 5px;
//     box-shadow: none;
//     padding: 0.4rem 0.75rem;
//     font-size: 0.85rem;
//     &.btn-primary {
//         color: $white;
//         background-color: $success;
//         border-color: $success;
//         // &:hover{
//         //     background-color: transparent;
//         //     color: $success;
//         // }
//     }
//     &.btn-outline-primary {
//         &:active {
//             border-color: $inputBorderColor;
//             background-color: transparent;
//             color: $success;
//         }
//         border-color: $inputBorderColor;
//         color: $success;
//         &:hover {
//             background-color: transparent;
//             color: $success;
//         }
//     }
//     &:disabled,
//     &.disabled {
//         opacity: 0.5;
//     }
//     &:focus {
//         box-shadow: none;
//     }
//     &.btn-link {
//         padding: 0;
//         font-weight: $semiboldFont;
//         color: $primary;
//         text-decoration: none;
//     }
//     &.btn-sm {
//         font-size: $font12;
//         padding: 0.3rem 0.75rem;
//         min-width: 57px;
//     }
// }
// .btn-secondary:not(:disabled):not(.disabled):active:focus,
// .btn-secondary:not(:disabled):not(.disabled).active:focus {
//     box-shadow: none;
// }

.MuiButton-root {
    &.editPencilBtn {
        border-radius: 50%;
        min-width: auto;
        text-align: center;
        padding: 0px;
        width: 30px;
        height: 30px;
        text-align: center;
        .MuiButton-startIcon{
            margin: 0 auto;
        }
    }
    &.MuiLoadingButton-root{
        &.editPencilBtn {
            .MuiButton-endIcon{
                margin-left: 0;
                margin-right: 0;
            }
        }
        
    }
}

