// @import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";

.carousel {
    .carousel-indicators {
        [data-bs-target] {
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
    &.carousel-dark {
        .carousel-inner {
            .carousel-caption {
                color: $carousel-caption-color;
                h6 {
                    font-weight: $boldFont;
                    margin-bottom: 0;
                }
                p {
                    color: $textColor;
                    margin-bottom: 0;
                    line-height: 1.3;
                }
            }
        }
        .carousel-indicators {
            bottom: 15px;
            [data-bs-target] {
                background-color: $inputBorderColor;
                margin: 0 5px;
                &.active {
                    background-color: #296941;
                }
            }
        }
    }
}

.listingSlider {
    position: relative;
    margin: 0 -13px;
    .slick-slide {
        padding: 10px 13px;
    }
    &.arrowStyle2 {
        .slick-arrow {
            z-index: 1;
            @include transition(500ms);
            &.slick-prev {
                left: 20px;
            }
            &.slick-next {
                right: 20px;
            }
            &.slick-disabled {
                display: none !important;
            }
        }
        // &:hover {
        //     .slick-arrow {
        //         display: block !important;
        //         opacity: 1;
        //         &.slick-disabled {
        //             display: none !important;
        //         }
        //     }
        // }
    }
}
.slick-arrow {
    box-shadow: 0 0 5px 0 rgba(0 0 0.2);
    &.slick-prev,
    &.slick-next,
    &.slick-prev:hover,
    &.slick-prev:focus,
    &.slick-next:hover,
    &.slick-next:focus {
        width: 35px;
        height: 35px;
        background-color: #fdfdfd;
        border-radius: 50%;
    }
    &:before,
    &:before {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        margin: 0 auto;
        opacity: 1;
    }
    &.slick-prev {
        left: -35px;
        &:before {
            // background: url("/images/previous-arrow.svg") center center no-repeat;
        }
    }
    &.slick-next {
        right: -35px;
        &:before {
            // background: url("/images/next-arrow.svg") center center no-repeat;
        }
    }
}
