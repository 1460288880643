header {
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  .navigation {
    .logo {
      a {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 900;
        color: $textColor;
        line-height: 24px;
      }
    }
    .menubar {
      margin-left: auto;
      > ul {
        display: flex;
        justify-content: space-between;
        > li {
          position: relative;
          padding-right: 15px;
          margin-right: 20px;
          > a {
            display: block;
            font-size: 14px;
            line-height: 19px;
            padding: 23px 0px;
            cursor: pointer;
            color: $textColor;
            font-weight: 500;
            position: relative;
            @include transition(450ms);
            &:hover {
              color: $primary;
              &::before {
                opacity: 1;
              }
            }
            &::before {
              position: absolute;
              width: 100%;
              left: 0;
              content: "";
              bottom: 0;
              background-color: $primary;
              height: 4px;
              opacity: 0;
              @include transition(450ms);
            }
          }
          &.hasDropdown {
            background-image: url(/assets/images/menu-dropdown.svg);
            background-repeat: no-repeat;
            background-position: right 53%;
            &:hover {
              .submenu {
                display: flex;
                -webkit-animation-name: fadeIn;
                animation-name: fadeIn;
              }
            }
          }
          &:last-child{
            &.hasDropdown {
              @media (min-width: 1199px) {
                .submenu {
                  left: auto;
                  right: 0;
                }
              }
            }
          }
        }
      }
      .submenu {
        background: #fff;
        position: absolute;
        width: 200px;
        filter: drop-shadow(0px 1px 7px rgba(35, 31, 32, 0.15));
        display: none;
        -webkit-animation-duration: 1.2s;
        -webkit-animation-timing-function: ease;
        -webkit-animation-fill-mode: both;
        -moz-animation-duration: 1.2s;
        -moz-animation-timing-function: ease;
        -moz-animation-fill-mode: both;
        -o-animation-duration: 1.2s;
        -o-animation-timing-function: ease;
        -o-animation-fill-mode: both;
        -ms-animation-duration: 1.2s;
        -ms-animation-timing-function: ease;
        -ms-animation-fill-mode: both;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-fill-mode: both;
        z-index: 10;
        left: 0;
        
        border: #DFDDDD solid 1px;
        border-top: $primary solid 4px;
        top: calc(100% - 4px);
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        ul {
          width: 100%;
          li {
            a {
              padding: 10px 15px;
              font-size: 12px;
              font-weight: 500;
              color: $textColor;
              display: block;
              &:hover{
                background-color: #F5F5F5;
                color: $primary;
              }
            }
            border-bottom: #DFDDDD solid 1px;
            &:last-child{
              border-bottom: none;
            }
          }
        }
      }
    }
    .profileDropdown {
      border-left: #dddddd solid 1px;
      padding-left: 20px;
      .un-profile-dropdown {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #888e97;
        text-align: center;
        font-weight: 700;
        line-height: 35px;
        cursor: pointer;
      }
      @include for-medium-desktop {
        padding-left: 0;
        border-left: none;
      }
    }
    @include for-medium-desktop {
      padding: 10px 0;
    }
    
  }
}

.mobileSlideMenu {
  .mobileLinks {
    padding: 15px 15px;
    display: block;
  }
  .mobile-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: #e5e5e5 solid 1px;
    font-size: 14px;
  }
  .submenu {
    ul {
      li {
        border-bottom: #e5e5e5 solid 1px;
        a{
          font-size: 0.85rem;
        }
      }
    }
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
    &.openMobileDropdown {
      max-height: 0;
      transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    }
  }
}
