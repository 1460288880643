.pillTypeRadioBtns {
    margin: 0 -15px;
    .pill-radio-btn {
        padding: 0 7px;
        &:last-child {
            
        }
        input:focus,
        .btn-check:focus + .btn-outline-primary,
        .btn-outline-primary:focus {
            box-shadow: none !important;
        }
        input {
            &.btn-check {
                + label {
                    width: auto;
                    &.btn-outline-primary {
                        font-size: 12px;
                        font-weight: 500;
                        border-radius: 35px;
                        color: $inputTextColor;
                        border-color: $inputBorderColor;
                        padding-top: 0.4rem;
                        padding-bottom: 0.4rem;
                        
                    }
                }
                &:checked {
                    + label {
                    }
                }
            }
        }
    }
}
