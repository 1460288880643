.overlay {
    height: calc(100% - 30px);
    left: 15px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    width: calc(100% - 15px);
    z-index: 100001;
    overflow-y: auto;
    padding: 15px 0;
    @include for-large-phone {
        left: 7px;
        width: calc(100% - 14px);
    }
}
.overlay-box {
    display: table;
    height: inherit;
    margin: 0 auto;
    width: 85%;
    border-radius: 5px;
    overflow: hidden;
    @include for-large-phone {
        width: 100%;
        table-layout: fixed;
    }
    &.small_overlay {
        width: 420px;
        @include for-large-phone {
            width: 100%;
        }
    }
    &.medium_overlay {
        width: 620px;
        @include for-large-phone {
            width: 100%;
        }
    }
    &.large_overlay {
        width: 960px;
        @include for-large-phone {
            width: 100%;
        }
    }
}
.overlay-box .content {
    display: table-cell;
    vertical-align: middle;
}
.overlay-box .closeBtn {
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 13px;
    z-index: 1002;
}
.overlay-box .overlay-header {
    font-size: 1rem;
    border-bottom: #b6c1ca solid 1px;
    font-weight: $boldFont;
    padding: 16px 20px;
    position: relative;
    color: #000;
    background-color: $modalHeaderBg;
    line-height: 1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.overlay-box .overlay-content {
    padding: 20px 20px;
}
.overlay-box .overlay-footer {
    padding: 9px 20px;
    background: $white;
    text-align: center;
}
.overlayBody {
    background: $white;
    position: relative;
    border-radius: 10px;
}

.overlay-box.category-addeditbox{
    width: 600px;
}

.overlay-box.large-size
{
    width: 80%;

    .overlay-content {
        height: 600px;
        overflow-y: scroll;
    }
    .overlay-content.height2 {
        height: 480px;
    }
    .overlay-content.height3 {
        height: calc(100% - 30px);
    }
    .overlay-content::-webkit-scrollbar{
        width: 10px;
    }
    .overlay-content::-webkit-scrollbar-thumb{
        border-radius: 0px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
    }
}

.overlay-box.medium-size
{
    width: 50%;
    
    .overlay-content {
        height: 400px;
        overflow-y: scroll;
    }
    .overlay-content.height2 {
        height: 300px;
    }
    .overlay-content::-webkit-scrollbar{
        width: 10px;
    }
    .overlay-content::-webkit-scrollbar-thumb{
        border-radius: 0px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
    }
}

.overlay-box.small-size
{
    width: 30%;
    .overlay-content {
        height: 400px;
        overflow-y: scroll;
    }
    .overlay-content.height2 {
        height: 240px;
    }
    .overlay-content::-webkit-scrollbar{
        width: 10px;
    }
    .overlay-content::-webkit-scrollbar-thumb{
        border-radius: 0px;
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #ccc;
    }
}