.breadcrumb{
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
}
.filter-with-table {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  background-color: #fff;
  padding: 15px;
}
