// Media Query Function
@mixin for-2560-desktop {
  @media (max-width: 2560px) {
    @content;
  }
}
@mixin for-1920-desktop {
  @media (max-width: 1920px) {
    @content;
  }
}
@mixin for-1800-desktop {
  @media (max-width: 1800px) {
    @content;
  }
}
@mixin for-1700-desktop {
  @media (max-width: 1700px) {
    @content;
  }
}
@mixin above-1600-desktop {
  @media (min-width: 1601px) {
    @content;
  }
}
@mixin for-1600-desktop {
  @media (max-width: 1600px) {
    @content;
  }
}
@mixin for-1400-desktop {
  @media (max-width: 1400px) {
    @content;
  }
}
@mixin for-large-desktop {
  @media (max-width: 1280px) {
    @content;
  }
}
@mixin above-1100-desktop {
  @media (min-width: 1100px) {
    @content;
  }
}
@mixin for-medium-desktop {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin above-tablet-only {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin below-1100-desktop {
  @media (max-width: 1099px) {
    @content;
  }
}
@mixin for-tablet-landscape {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin above-tablet-portrait {
  @media (min-width: 981px) {
    @content;
  }
}
@mixin for-tablet-portrait {
  @media (max-width: 980px) {
    @content;
  }
}
@mixin for-tablet-only {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin for-large-phone {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin for-phone {
  @media (max-width: 568px) {
    @content;
  }
}

/*common css*/


@mixin flexBasis {
  flex-grow: 1;
  flex-basis: 0;
}
@mixin flexAuto {
  flex: 0 0 auto;
}
@mixin transition($speed) {
  transition: all $speed ease; 
  -moz-transition: all $speed ease; 
  -webkit-transition: all $speed ease;
}
